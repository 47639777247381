import { graphql } from 'gatsby';
import React from 'react';
import { Header, Item, ItemList, Layout, SEO } from '../components';
import PageProps from '../models/PageProps';
import { queryResultToPresentationList } from '../utils/contentList';

const PodcastPage = ({ data }: PageProps) => {
  const podcasts = queryResultToPresentationList(data, ['podcast']);

  return (
    <Layout>
      <SEO titlePrefix='Podcasts |' isPost={false} />
      <Header title='Podcasts' icon='microphone' subHeading='Engineering & UX Podcast' />
      <ItemList>
        {podcasts.map((podcast, idx) => (
          <Item key={idx} {...podcast} />
        ))}
      </ItemList>
    </Layout>
  );
};
export default PodcastPage;

export const IndexQuery = graphql`
  query {
    allPodcastRssFeedEpisode(sort: { fields: item___isoDate, order: DESC }) {
      totalCount
      nodes {
        item {
          title
          link
          content
          isoDate
          enclosure {
            length
            type
            url
          }
          guid
        }
      }
    }
  }
`;
