import { IconDefinition } from '@telia/styleguide';
import Data, { GithubRepo } from '../models/Data';
import PodcastType from '../models/PodcastType';
import Post from '../models/Post';

export interface PageItem {
  title: string;
  date: string;
  sortDate: Date;
  url: string;
  excerpt: string;
  timeToRead?: number;
  type: 'blog' | 'repo' | 'podcast';
  icon: IconDefinition;
  image: string;
}

export function blogToItem({ node: blogPost }: { node: Post }): PageItem {
  const [day, month, year] = blogPost.frontmatter.date.split('.');
  return {
    ...blogPost.frontmatter,
    sortDate: new Date(Date.UTC(Number(year), Number(month) - 1, Number(day))),
    timeToRead: blogPost.timeToRead,
    excerpt: blogPost.excerpt,
    type: 'blog',
    icon: 'document-edit',
    image: blogPost.frontmatter.image?.publicURL || '/images/blog.jpg',
    url: `/blog/${blogPost.fields.slug}`,
  };
}

function repoToItem({ repo: repository }: GithubRepo): PageItem {
  return {
    title: repository.name,
    date: new Date(repository.lastUpdated).toLocaleDateString(),
    sortDate: new Date(repository.lastUpdated),
    url: repository.url,
    excerpt: repository.description,
    type: 'repo',
    icon: 'laptop',
    image: '/images/github.png',
  };
}

function podcastToItem({ item: podcast }: { item: PodcastType }): PageItem {
  return {
    title: podcast.title,
    date: new Date(podcast.isoDate).toLocaleDateString(),
    sortDate: new Date(podcast.isoDate),
    url: podcast.link,
    excerpt: `${podcast.content.substring(0, 200)}...`,
    // timeToRead: Number(podcast?.enclosure?.length),
    type: 'podcast',
    icon: 'microphone',
    image: '/images/podcast.jpg',
  };
}

export function queryResultToPresentationList(data: Data, contentTypes?: ('blog' | 'repo' | 'podcast')[]) {
  const { allMarkdownRemark, github, allPodcastRssFeedEpisode } = data;

  const { edges: blog } = allMarkdownRemark || { edges: [] };
  const {
    payload: { repositories: repo },
  } = github || { payload: { repositories: [] } };
  const { nodes: podcast } = allPodcastRssFeedEpisode || { nodes: [] };

  return [...blog.map(blogToItem), ...repo.map(repoToItem), ...podcast.map(podcastToItem)]
    .sort(({ sortDate: dateA }, { sortDate: dateB }) => dateB.valueOf() - dateA.valueOf())
    .filter(({ type }) => !contentTypes || contentTypes.length === 0 || contentTypes.includes(type));
}
